import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

import { actions as userActions } from '../../reducers/user';
import { actions as addressActions } from '../../reducers/address';
import ScrollToTop from '../ScrollToTop';
import { callToastError } from '../../utils/callToast';
import { FaArrowRight } from 'react-icons/fa';

const FormPersonalInfo = ({ userData, nextStepAfterForm }) => {
  const dispatch = useDispatch();
  const [formInfo, setFormInfo] = useState({
    cpf: '',
    phone: '',
    zipcode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isMountedRef = useRef(true); // Track if the component is mounted

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false; // Cleanup: Set to false when component unmounts
    };
  }, [dispatch]);

  const address = useMemo(() => userData?.address?.[0] || {}, [userData]);

  // Initialize form fields with user data if relevant fields change
  useEffect(() => {
    if (userData && isMountedRef.current) {
      setFormInfo(prevFormInfo => {
        const newFormInfo = {
          ...prevFormInfo,
          cpf: userData.documentNumber || '',
          phone: formatPhoneNumber(userData) || '',
          zipcode: address?.zipcode || '',
          street: address?.street || '',
          number: address?.number || '',
          complement: address?.complement || '',
          neighborhood: address?.neighborhood || '',
          city: address?.city || '',
          state: address?.state || '',
        };

        // Compare the old formInfo with new values, only update if different
        const hasChanged = Object.keys(newFormInfo).some(
          key => newFormInfo[key] !== prevFormInfo[key]
        );

        return hasChanged ? newFormInfo : prevFormInfo;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.documentNumber, userData?.phoneNumber, address.zipcode, address.street, address.number, address.complement, address.neighborhood, address.city, address.state]);

  useEffect(() => {
    if (address.cep && isMountedRef.current) {
      setFormInfo(prevFormInfo => ({
        ...prevFormInfo,
        street: address.logradouro,
        neighborhood: address.bairro,
        city: address.localidade,
        state: address.uf
      }));
      setErrors(prevErrors => ({ ...prevErrors, zipcode: undefined }));
    } else if (address.error && isMountedRef.current) {
      setErrors(prevErrors => ({ ...prevErrors, zipcode: 'CEP não encontrado' }));
    }
  }, [address]);

  // Helper function to format phone number
  const formatPhoneNumber = (userData) => {
    if (userData.ddd && userData.phoneNumber) {
      const phone = `${userData.phoneNumber}`;
      const isMobile = phone.length === 9;
      return `(${userData.ddd}) ${isMobile ? phone.slice(0, 1) + ' ' + phone.slice(1) : phone}`;
    }
    return '';
  };

  const handleInputChange = (name, value) => {
    setFormInfo(prevFormInfo => ({ ...prevFormInfo, [name]: value }));
  };

  const handleValidate = () => {
    const newErrors = {};
    let hasErrors = false;
    const { cpf, phone, zipcode, street, number, neighborhood, city, state } = formInfo;

    // Validate cpf (CPF or CNPJ)
    if (!cpf) {
      newErrors.cpf = 'CPF/CNPJ é obrigatório';
    } else {
      const cleanedDoc = cpf.replace(/[^\d]+/g, '');
      if (cleanedDoc.length !== 11 && cleanedDoc.length !== 14) {
        newErrors.cpf = 'CPF/CNPJ inválido';
      }
      // Add CPF/CNPJ validation logic if necessary
    }

    // Validate phone
    if (!phone) {
      newErrors.phone = 'Telefone é obrigatório';
    } else {
      const cleanedPhone = phone.replace(/[^\d]+/g, '');
      if (cleanedPhone.length < 10 || cleanedPhone.length > 11) {
        newErrors.phone = 'Telefone inválido';
      }
    }

    // Validate zip code
    if (!zipcode) {
      newErrors.zipcode = 'CEP é obrigatório';
    } else {
      const cleanedZip = zipcode.replace(/[^\d]+/g, '');
      if (cleanedZip.length !== 8) {
        newErrors.zipcode = 'CEP inválido';
      }
    }

    // Validate address fields
    if (!street) {
      newErrors.street = 'Rua é obrigatória';
    }
    if (!number) {
      newErrors.number = 'Número é obrigatório';
    }
    if (!neighborhood) {
      newErrors.neighborhood = 'Bairro é obrigatório';
    }
    if (!city) {
      newErrors.city = 'Cidade é obrigatória';
    }
    if (!state) {
      newErrors.state = 'Estado é obrigatório';
    }

    if (Object.keys(newErrors).length) {
      hasErrors = true;
    }

    return { hasErrors, errors: newErrors };
  };

  const onHandleSubmit = async () => {
    if (isSubmitting) return;

    const { hasErrors, errors } = handleValidate();
    if (hasErrors) {
      setErrors(errors);
      callToastError('Por favor, corrija os erros no formulário');
      return;
    }

    setErrors({});
    setIsSubmitting(true);

    try {
      dispatch(userActions.changeProfileDataRequest({ cpf: formInfo.cpf, phone: formInfo.phone }));

      const id = userData.address?.id;
      const name = id ? userData.address.name : 'Endereço Principal';
      dispatch(addressActions.changeAddressRequest({
        id,
        name,
        recipientName: `${userData.firstName} ${userData.lastName}`,
        zipcode: formInfo.zipcode,
        street: formInfo.street,
        number: formInfo.number,
        complement: formInfo.complement,
        neighborhood: formInfo.neighborhood,
        city: formInfo.city,
        state: formInfo.state,
        country: 'Brasil',
      }));

    
      if (isMountedRef.current) {
        nextStepAfterForm();
        setIsSubmitting(false);
        // setTimeout(() => {
        //   nextStepAfterForm();
        //   setIsSubmitting(false);
        // }, 1000);
      }
    } catch (error) {
      if (isMountedRef.current) {
        callToastError('Ocorreu um erro ao salvar os dados');
        setIsSubmitting(false);
      }
    }
  };

  // Fetch address based on zip code
  const handleZipCodeBlur = () => {
    const cleanedZip = formInfo.zipcode.replace(/[^\d]+/g, '');
    if (cleanedZip.length === 8 && isMountedRef.current) {
      fetch(`https://viacep.com.br/ws/${cleanedZip}/json/`)
        .then(response => response.json())
        .then(data => {
          if (!data.erro && isMountedRef.current) {
            setFormInfo(prevFormInfo => ({
              ...prevFormInfo,
              street: data.logradouro,
              neighborhood: data.bairro,
              city: data.localidade,
              state: data.uf
            }));
            setErrors(prevErrors => ({ ...prevErrors, zipcode: undefined }));
          } else if (isMountedRef.current) {
            setErrors(prevErrors => ({ ...prevErrors, zipcode: 'CEP não encontrado' }));
          }
        })
        .catch(error => {
          if (isMountedRef.current) {
            setErrors(prevErrors => ({ ...prevErrors, zipcode: 'Erro ao buscar CEP' }));
          }
        });
    }
  };

  return (
    <>
      <ScrollToTop />
      <div className="main mt-4">
        <div className="container">
          <div className="row">
            {/* Document Number (CPF/CNPJ) */}
            <InputMask
              mask='999.999.999-99'
              formatChars={{ 9: '[0-9]' }}
              placeholder="CPF"
              type="text"
              className={classNames('input-box', { invalid: errors?.cpf })}
              value={formInfo.cpf}
              onChange={e => handleInputChange('cpf', e.currentTarget.value)}
              maskChar={null}
            />
            {errors?.cpf && <span className="feedback-invalid">{errors?.cpf}</span>}

            {/* Phone Number */}
            <InputMask
              mask="(99) 99999-9999" // Always allow up to 11 digits
              formatChars={{ 9: '[0-9]' }}
              placeholder="Telefone"
              type="tel"
              className={classNames('input-box', { invalid: errors?.phone })}
              value={formInfo.phone}
              maskChar={null}
              onChange={e => {
                let value = e.currentTarget.value.replace(/\D/g, ''); // Remove non-numeric characters
                handleInputChange('phone', value); // Update state
              }}
            />
            {errors?.phone && <span className="feedback-invalid">{errors?.phone}</span>}

            
            {/* Zip Code (CEP) */}
            <InputMask
              mask="99999-999"
              formatChars={{ 9: '[0-9]' }}
              placeholder="CEP"
              type="text"
              className={classNames('input-box', { invalid: errors?.zipcode })}
              value={formInfo.zipcode}
              maskChar={null}
              onChange={e => handleInputChange('zipcode', e.currentTarget.value)}
              onBlur={handleZipCodeBlur}
            />
            {errors?.zipcode && <span className="feedback-invalid">{errors?.zipcode}</span>}

            {/* Street */}
            <input
              type="text"
              placeholder="Rua"
              maxLength="255"
              className={classNames('input-box', { invalid: errors?.street })}
              value={formInfo.street}
              onChange={e => handleInputChange('street', e.currentTarget.value)}
            />
            {errors?.street && <span className="feedback-invalid">{errors?.street}</span>}

            {/* Number */}
            <input
              type="text"
              placeholder="Número"
              maxLength="10"
              className={classNames('input-box', { invalid: errors?.number })}
              value={formInfo.number}
              onChange={e => handleInputChange('number', e.currentTarget.value)}
            />
            {errors?.number && <span className="feedback-invalid">{errors?.number}</span>}

            {/* Complement */}
            <input
              type="text"
              placeholder="Complemento"
              maxLength="255"
              className="input-box"
              value={formInfo.complement}
              onChange={e => handleInputChange('complement', e.currentTarget.value)}
            />

            {/* Neighborhood */}
            <input
              type="text"
              placeholder="Bairro"
              maxLength="255"
              className={classNames('input-box', { invalid: errors?.neighborhood })}
              value={formInfo.neighborhood}
              onChange={e => handleInputChange('neighborhood', e.currentTarget.value)}
            />
            {errors?.neighborhood && <span className="feedback-invalid">{errors?.neighborhood}</span>}

            {/* City */}
            <input
              type="text"
              placeholder="Cidade"
              maxLength="255"
              className={classNames('input-box', { invalid: errors?.city })}
              value={formInfo.city}
              onChange={e => handleInputChange('city', e.currentTarget.value)}
            />
            {errors?.city && <span className="feedback-invalid">{errors?.city}</span>}

            {/* State */}
            <input
              type="text"
              placeholder="Estado (UF)"
              maxLength="2"
              className={classNames('input-box', { invalid: errors?.state })}
              value={formInfo.state}
              onChange={e => handleInputChange('state', e.currentTarget.value)}
            />
            {errors?.state && <span className="feedback-invalid">{errors?.state}</span>}

            <div className="row">
              <div className="col-12">
                <div className="box-btn-submit">
                  <button
                    className="btn btn-lg btn-primary text-right"
                    onClick={onHandleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Processando...' : 'Continuar'}
                    {isSubmitting ? null : <FaArrowRight className="ml-1" />}
                  </button>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </>
  );
};

FormPersonalInfo.defaultProps = {
  userData: {},
  nextStepAfterForm: () => {},
};

FormPersonalInfo.propTypes = {
  userData: PropTypes.object,
  nextStepAfterForm: PropTypes.func.isRequired,
};

export default FormPersonalInfo;
