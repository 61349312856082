import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { types, actions as userActions } from '../../reducers/user';
import { actions as cartActions, initialState as initialCartState } from '../../reducers/cart';
import { actions as modalActions } from '../../reducers/modal';
import { actions as coursesActions } from '../../reducers/courses';
import {
  postRegister,
  postLogin,
  postLogout,
  getUserInfo,
  postForgotPassword,
  postForgotPasswordReset,
  getForgotPasswordValidity,
  patchChangePassword,
  jsonFormatChangePassword,
  patchChangeProfileImage,
  putChangeUserInfo,
  jsonFormatPersonalInfo,
  patchPreferences,
  jsonFormatPreferences,
  setGender,
  setDateOfBirth
} from '../../api/users';
import { saveState, loadState, removeState } from '../../utils/statePersistence';
import snakeToCamel from '../../utils/snakeToCamel';
import { PAGE_HOME, PAGE_MY_PROFILE, PAGE_MY_COURSES, PAGE_MY_FAVORITES, PAGE_MY_CERTIFICATES, PAGE_MY_PURCHASES, PAGE_WATCH } from '../../constants';

const protectedRoutes = [
  PAGE_MY_PROFILE,
  PAGE_MY_COURSES,
  PAGE_MY_FAVORITES,
  PAGE_MY_CERTIFICATES,
  PAGE_MY_PURCHASES,
  PAGE_WATCH,
];

function* register(action) {
  try {
    const formRegister = action.payload;
    const response = yield call(postRegister, formRegister);

    window.dataLayer.push({
      'event': 'userRegistered',
      'user': response?.data?.data
    });

    const { accessToken, refreshToken } = snakeToCamel(response?.data);

    // Save both tokens
    saveState(accessToken, 'accessToken');
    saveState(refreshToken, 'refreshToken');

    const user = snakeToCamel(response?.data.data);
    yield put(userActions.set(user));
    saveState(user, 'user');

    const cart = yield select(state => state.cart);
    if (cart.id) {
      yield put(cartActions.patchRequest(cart.id));
    }
  } catch (error) {
    yield put(userActions.error({ error: 'E-mail já cadastrado no sistema' }));
  }
}

function* login(action) {
  try {
    const formLogin = action.payload;
    const responseLogin = yield call(postLogin, formLogin);
    const { accessToken, refreshToken } = snakeToCamel(responseLogin?.data);

    // Save both tokens to local storage
    saveState(accessToken, 'accessToken');
    saveState(refreshToken, 'refreshToken');

    // Fetch user information after login
    const response = yield call(getUserInfo);
    window.dataLayer.push({
      'event': 'userLogged',
      'user': response?.data?.data
    });

    const user = snakeToCamel(response?.data.data);
    yield put(userActions.set(user));
    saveState(user, 'user');

    // Check and update cart if necessary
    const cart = yield select(state => state.cart);
    if (cart.id) {
      yield put(cartActions.patchRequest(cart.id));
    }

    // Reload courses if user is on /courses page
    const currentPath = window.location.pathname;
    if (currentPath === '/cursos') {
      const filterQueries = yield select(state => state.courses.filterQueries); // Retrieve the current filterQueries from the state
      yield put(coursesActions.getWithFilter(filterQueries));
    }
  } catch (error) {
    yield put(userActions.error({ error: 'E-mail ou senha inválidos' }));
  }
}

function* loginSocialNetwork(action) {
  try {
    const { accessToken } = action.payload;

    // Save tokens in local storage
    saveState(accessToken, 'accessToken');

    // Fetch user information with the access token
    const response = yield call(getUserInfo);
    window.dataLayer.push({
      event: 'userLogged',
      user: response?.data?.data
    });

    const user = snakeToCamel(response.data.data);
    yield put(userActions.set(user));
    yield put(modalActions.setAuthModalIsOpen(false));

    saveState(user, 'user');

    // Check and update cart if necessary
    const cart = yield select(state => state.cart);
    if (cart.id) {
      yield put(cartActions.patchRequest(cart.id));
    }

    // Refresh courses if user is on /cursos page
    const currentPath = window.location.pathname;
    if (currentPath === '/cursos') {
      const filterQueries = yield select(state => state.courses.filterQueries);
      yield put(coursesActions.getWithFilter(filterQueries));
    }
  } catch (error) {
    yield put(userActions.error(error));
    removeState('accessToken'); // Cleanup tokens on error
  } finally {
    window.close();
  }
}

function* loginPartners(action) {
  try {
    const {
      payload: { accessToken, history }
    } = action;

    // Save both tokens to local storage
    saveState(accessToken, 'accessToken');

    // Fetch user information
    const response = yield call(getUserInfo);
    window.dataLayer.push({
      'event': 'userLogged',
      'user': response?.data?.data
    });

    const user = snakeToCamel(response.data.data);
    yield put(userActions.set(user));
    yield put(modalActions.setAuthModalIsOpen(false));

    saveState(user, 'user');

    // Check and update cart if necessary
    const cart = yield select(state => state.cart);
    if (cart.id) {
      yield put(cartActions.patchRequest(cart.id));
    } else {
      history.push(PAGE_MY_COURSES);
    }
  } catch (error) {
    const { payload: { history } } = action;
    yield put(userActions.error(error));
    saveState('', 'accessToken');
    history.push(PAGE_HOME);
  }
}

function* forgotPassword(action) {
  try {
    const { email } = action.payload;
    yield call(postForgotPassword, { email });
    yield put(userActions.set({ forgotPasswordSuccess: 'Perfeito, enviamos para o seu email um link para recriar a senha.' }));
  } catch (error) {
    yield put(userActions.error({ forgotPassword: 'Não foi encontrado um usuário com esse e-mail.' }));
  }
}

function* checkValidityToken(action) {
  try {
    const { token } = action.payload;
    const response = yield call(getForgotPasswordValidity, token);
    const tokenValidity = snakeToCamel(response.data);
    yield put(userActions.set({ tokenValidity }));
  } catch (error) {
    yield put(userActions.error({ tokenValidity: { error: 'Token não é mais válido. Peça novamente o e-mail.' } }));
  }
}

function* resetPassword(action) {
  try {
    const { payload } = action;
    yield call(postForgotPasswordReset, payload);
    yield put(userActions.set({ tokenValidity: { success: 'Senha alterada com sucesso.' } }));
  } catch (error) {
    yield put(userActions.error(error));
  }
}

function* changePassword(action) {
  try {
    const { payload } = action;
    const response = yield call(patchChangePassword, jsonFormatChangePassword(payload));
    const { accessToken } = snakeToCamel(response.data?.data);
    saveState(accessToken, 'accessToken');
    yield put(userActions.set({ messagePasswordSuccess: 'Senha alterada com sucesso!' }));
  } catch (error) {
    yield put(userActions.error(error));
  }
}

function* changeEmail(action) {
  try {
    const { payload } = action;
    const response = yield call(putChangeUserInfo, payload);
    const user = snakeToCamel(response?.data.data);
    yield put(userActions.set({ ...user, messageEmailSuccess: 'E-mail alterado com sucesso.' }));
  } catch (error) {
    yield put(userActions.error(error));
  }
}

function* changeProfileImage(action) {
  try {
    const { payload } = action;
    yield call(patchChangeProfileImage, payload);
    yield put(userActions.changeProfileImageSuccess());
  } catch (error) {
    yield put(userActions.error(error));
  }
}

function* logout(action) {
  try {
    // Call the backend logout endpoint
    yield call(postLogout);

    // Clear Redux state for user and cart, update data layer, and remove tokens
    yield all([put(userActions.logout()), put(cartActions.resetCart())]);

  } catch (error) {
    console.error('Logout failed on backend:', error);
    yield put(userActions.error(error));
  } finally {

    window.dataLayer.push({
      event: 'userLoggedOut',
      user: {},
    });

    // Ensure frontend state and storage are cleared regardless of backend logout success
    removeState('accessToken');
    removeState('refreshToken');
    removeState('token_expiry');
    saveState({}, 'user');
    saveState(initialCartState, 'cart');
    // Get the current route path
    const currentPath = window.location.pathname;

    // Check if the current path is in the list of protected routes
    const isProtectedRoute = protectedRoutes.some(route => currentPath.startsWith(route));

    // If the user is on a protected route, redirect to the home page
    if (isProtectedRoute && action.payload && action.payload.history) {
      action.payload.history.push(PAGE_HOME);
    } else {
      window.location.reload();
    }
  }
}

function simpleLogout(action) {
  const { message } = action.payload || {};
  const logoutMessage = message || 'Você foi desconectado com sucesso.';
  localStorage.setItem('logoutToastMessage', logoutMessage);

  window.dataLayer.push({
    event: 'userLoggedOut',
    user: {},
  });

  // Ensure frontend state and storage are cleared regardless of backend logout success
  removeState('accessToken');
  removeState('refreshToken');
  removeState('token_expiry');
  saveState({}, 'user');
  saveState(initialCartState, 'cart');
  // Get the current route path
  const currentPath = window.location.pathname;

  // Check if the current path is in the list of protected routes
  const isProtectedRoute = protectedRoutes.some(route => currentPath.startsWith(route));

  // If the user is on a protected route, redirect to the home page
  if (isProtectedRoute && action.payload && action.payload.history) {
    action.payload.history.push(PAGE_HOME);
  } else {
    window.location.reload();
  }
}

function* changeProfileData(action) {
  try {
    const { payload } = action;
    const { cpf: documentNumber, phone } = payload;
    const phoneNumber = phone.replace(/[^0-9]+/g, '');
    const ddd = phoneNumber.substr(0, 2);
    const number = phoneNumber.substr(2);
    const response = yield call(putChangeUserInfo, jsonFormatPersonalInfo({ documentNumber, ddd, phoneNumber: number }));
    const user = snakeToCamel(response?.data.data);
    yield put(userActions.set({ ...user, messageDataSuccess: 'Dados alterados com sucesso.' }));
    saveState(user, 'user');
  } catch (error) {
    yield put(userActions.error(error));
  }
}

function* changeSpeed(action) {
  try {
    const { payload } = action;
    const response = yield call(patchPreferences, jsonFormatPreferences({ videoSpeed: payload }));
    const preferences = snakeToCamel(response?.data);
    yield put(userActions.set({ preferences }));
    const user = loadState('user');
    user.preferences = preferences;
    saveState(user, 'user');
  } catch (error) {
    yield put(userActions.error(error));
    /* eslint-disable-next-line no-console */
    console.log('error', error);
  }
}

function* changeResolution(action) {
  try {
    const { payload } = action;
    const response = yield call(patchPreferences, jsonFormatPreferences({ resolutionVideo: payload }));
    const preferences = snakeToCamel(response?.data);
    yield put(userActions.set({ preferences }));
    const user = loadState('user');
    user.preferences = preferences;
    saveState(user, 'user');
  } catch (error) {
    yield put(userActions.error(error));
    /* eslint-disable-next-line no-console */
    console.log('error', error);
  }
}

function* setUserGender(action) {
  try {
    const { payload } = action;
    const response = yield call(setGender, payload);
    const user = snakeToCamel(response?.data.data);
    yield put(userActions.set(user));
    saveState(user, 'user');
  } catch (error) {
    yield put(userActions.error(error));
    /* eslint-disable-next-line no-console */
    console.log('error', error);
  }
}

function* setUserDateOfBirth(action) {
  try {
    const { payload } = action;
    const response = yield call(setDateOfBirth, payload);
    const user = snakeToCamel(response?.data.data);
    yield put(userActions.set(user));
    saveState(user, 'user');
  } catch (error) {
    yield put(userActions.error(error));
    /* eslint-disable-next-line no-console */
    console.log('error', error);
  }
}

export default function* watchUser() {
  yield takeEvery(types.REGISTER_REQUEST, register);
  yield takeEvery(types.LOGIN_REQUEST, login);
  yield takeEvery(types.LOGIN_SOCIAL_REQUEST, loginSocialNetwork);
  yield takeEvery(types.LOGIN_PARTNERS, loginPartners);
  yield takeEvery(types.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeEvery(types.TOKEN_VALIDITY_REQUEST, checkValidityToken);
  yield takeEvery(types.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeEvery(types.CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeEvery(types.CHANGE_EMAIL_REQUEST, changeEmail);
  yield takeEvery(types.CHANGE_PROFILE_IMAGE_REQUEST, changeProfileImage);
  yield takeEvery(types.CHANGE_PROFILE_DATA, changeProfileData);
  yield takeEvery(types.LOGOUT, simpleLogout);
  yield takeEvery(types.LOGOUT_REQUEST, logout);
  yield takeEvery(types.CHANGE_SPEED_REQUEST, changeSpeed);
  yield takeEvery(types.CHANGE_RESOLUTION_REQUEST, changeResolution);
  yield takeEvery(types.SET_USER_GENDER, setUserGender);
  yield takeEvery(types.SET_USER_DATE_OF_BIRTH, setUserDateOfBirth);
}
