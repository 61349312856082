import React, { useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Payment } from '@mercadopago/sdk-react';
import { useDispatch } from 'react-redux';
import { actions } from '../../reducers/cart';
import { postPayment } from '../../api/payments';
import { toast } from 'react-toastify';

const PaymentForm = ({ cart, currentUser, visual, nextStep, currentStep, setPayment }) => {
  const paymentRef = useRef(null);
  const dispatch = useDispatch();
  const visualRef = useRef(visual);

  // store all initial values in a ref to avoid re-rendering the payment component
  const initialValues = useRef({
    amount: cart.grandTotal,
    // preferenceId: cart.mpPreferenceId,
    payer: {
      entityType: 'individual',
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      email: currentUser?.email,
      identification: {
        type: 'CPF',
        number: currentUser?.documentNumber
      },
      address: {
        zipCode: currentUser?.address?.[0]?.zipcode || '',
        federalUnit: currentUser?.address?.[0]?.state || '',
        city: currentUser?.address?.[0]?.city || '',
        neighborhood: currentUser?.address?.[0]?.neighborhood || '',
        streetName: currentUser?.address?.[0]?.street || '',
        streetNumber: currentUser?.address?.[0]?.number || '',
        complement: currentUser?.address?.[0]?.complement || ''
      },
    }
  });

  // State to manage re-rendering
  const [paymentRenderKey, setPaymentRenderKey] = useState(0);

  const renderPayment = useMemo(() => {
    if (paymentRef.current) {
      return null;
    }

    if (currentStep !== 2) {
      return null;
    }

    const onSubmit = async formData => {
      try {
        const dataToSubmit = { ...formData, cart };
        const response = await postPayment(dataToSubmit);

        if (!response.data.data) {
          toast.error('Erro ao processar pagamento. Tente novamente.');
          return;
        }

        setPayment(response.data.data);
        dispatch(actions.resetCart());
        nextStep();
      } catch (error) {
        toast.error('Erro ao processar pagamento. Tente novamente.');
        // Force a re-render by updating the paymentRenderKey
        paymentRef.current = null;
        setPaymentRenderKey(prevKey => prevKey + 1);
      }
    };

    return (
      <>
        <div ref={paymentRef}>
          <Payment
            key={paymentRenderKey} // Ensure this component re-renders when the key changes
            initialization={initialValues.current}
            customization={{
              visual: visualRef.current,
              paymentMethods: {
                creditCard: 'all',
                ticket: 'bolbradesco',
                bankTransfer: 'all',
                minInstallments: 1,
                maxInstallments: cart.maxInstallments 
              }
            }}
            onSubmit={formData => onSubmit(formData)}
          />
        </div>
      </>
    );
  }, [cart, currentStep, dispatch, nextStep, setPayment, paymentRenderKey]);

  return (
    <>
      <div className="title-bar">
        <div className='title-container'>
          <h3>Escolha a forma de pagamento</h3>
          <p>Você está em um ambiente seguro.</p>
        </div>
      </div>
      <div className="cart__payment mb-5 pb-5" ref={paymentRef}>
        {renderPayment}
      </div>
    </>
  );
};

PaymentForm.propTypes = {
  cart: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  visual: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  setPayment: PropTypes.func.isRequired
};

export default PaymentForm;
