import axios from 'axios';
import store from '../store';
import { matchPath } from 'react-router-dom';
import { getToken, refreshToken, saveState } from '../utils/statePersistence';
import { BASE_URL_API } from '../constants';
import { actions as userActions } from '../reducers/user';

const noCacheControlEndpoints = [
  { method: 'get', endpoint: 'v1/carts/:id' },
  { method: 'get', endpoint: 'v1/carts/:id/coupon/:id/validate' },
  { method: 'get', endpoint: 'v1/courses/:id/watch' },
  { method: 'get', endpoint: 'v1/students/:id' },
  { method: 'get', endpoint: 'v1/enrollments/:id' },
  { method: 'get', endpoint: 'v1/orders/:id' },
  { method: 'get', endpoint: 'v1/attachments/:id' },
  { method: 'get', endpoint: 'v1/users/me' }
];

// Helper function to match routes for cache control
const matchesRoute = ({ url, method }, endpoints) => {
  const matchesMethod = endpoints.some(route => route.method === method);
  const matchesURL = endpoints.some(route =>
    matchPath(url, { path: route.endpoint, exact: true, strict: false })
  );

  return !!(matchesMethod && matchesURL);
};

// Create Axios instance for API
const api = axios.create({
  baseURL: BASE_URL_API,
  responseType: 'json'
});

// Request Interceptor
api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // Cache Control
  const noCache = matchesRoute({ url: config.url, method: config.method }, noCacheControlEndpoints);
  if (noCache) {
    config.headers['Cache-Control'] = 'no-cache';
  }
  return config;
});

// Response Interceptor to handle token expiration
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Check if error is due to expired token and retry has not been attempted
    if (error.response && error.response.status === 401 && !originalRequest._retry && !originalRequest._logoutAttempted) {
      originalRequest._retry = true; // Mark request as retried to avoid loops
      originalRequest._logoutAttempted = true; // Mark logout as attempted

      // Attempt to refresh the token
      const newAccessToken = await refreshToken();
      if (newAccessToken) {
        saveState(newAccessToken, 'accessToken'); // Save the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return api(originalRequest); // Retry original request with new token
      } else {
        // Dispatch logout action only once
        store.dispatch(userActions.logout('Sua sessão expirou. Faça login novamente.'));
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
