import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { actions as cartActions } from '../../reducers/cart';
import { actions as purchasesActions } from '../../reducers/purchases';
import { actions as modalActions } from '../../reducers/modal';
import { removeState } from '../../utils/statePersistence';
import {
  AUTH_LOGIN,
  CREDITS,
  BASE_URL_CDN,
  PAGE_COURSES,
  MERCADOPAGO_KEY
} from '../../constants';
import CartSummary from './CartSummary';
import FormPersonalInfo from './FormPersonalInfo';
import PaymentForm from './PaymentForm';
import PaymentStatus from './PaymentStatus';

const MAX_STEP = 3;
const MIN_STEP = 0;

const logo = `${BASE_URL_CDN}app/assets/images/LOGO.svg`;

const ModalCart = ({ setAuthType }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const currentUser = useSelector(state => state.user);
  const [currencyMoney, setCurrencyMoney] = useState(true);
  const [payment, setPayment] = useState(null);
  const closedModalRef = useRef(false);

  const cartModalIsOpen = useSelector(state => state.modal.cartModalIsOpen);
  const setCartModalIsOpen = useCallback(
    bool => {
      dispatch(modalActions.setCartModalIsOpen(bool));
    },
    [dispatch]
  );

  const authModalIsOpen = useSelector(state => state.modal.authModalIsOpen);
  const setAuthModalIsOpen = useCallback(
    bool => {
      dispatch(modalActions.setAuthModalIsOpen(bool));
    },
    [dispatch]
  );

  const emptyCart = cart?.products?.length === 0;
  const notEnoughCredits = !currencyMoney && cart?.availableCredits < cart.totalCredits;
  const disabledPurchaseCredits = !currencyMoney && (notEnoughCredits || !cart?.totalCredits);
  const cartIsLoading = useSelector(state => state.cart.isFetching);

  const handleChooseMoreCourses = useCallback(() => {
    setCartModalIsOpen(false);
    history.push(PAGE_COURSES);
  }, [setCartModalIsOpen, history]);

  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = useCallback((setLoading = true) => {
    setCurrentStep(prevStep => Math.min(prevStep + 1, MAX_STEP));
  }, []);

  const previousStep = useCallback(() => {
    setCurrentStep(prevStep => Math.max(prevStep - 1, MIN_STEP));
  }, []);

  const nextStepAfterForm = useCallback(() => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            products: cart.products.map(product => ({
              name: product.title,
              id: product.productId,
              price: product.prices?.salePrice || product.prices?.price,
              brand: 'Descola',
              quantity: 1
            }))
          }
        }
      });
    }
    nextStep(false);
  }, [cart.products, nextStep]);

  useEffect(() => {
    initMercadoPago(MERCADOPAGO_KEY, { locale: 'pt-BR' });
  }, []);

  const onHandleFinishPurchase = useCallback(() => {
    if (currentUser?.email) {
      if (currencyMoney) {
        nextStep();
      } else {
        dispatch(
          purchasesActions.createOrderRequest(
            {
              payment: {
                type: CREDITS
              },
              cart
            },
            history
          )
        );
      }
    } else {
      setAuthType(AUTH_LOGIN);
      setAuthModalIsOpen(true);
    }
  }, [
    currentUser,
    currencyMoney,
    nextStep,
    dispatch,
    cart,
    history,
    setAuthType,
    setAuthModalIsOpen
  ]);

  const onCloseModalCart = useCallback(() => {
    closedModalRef.current = true;
    if (currentStep === 3) {
      dispatch(cartActions.resetCart());
      removeState('cart');
    }

    setCurrentStep(0);
    setCartModalIsOpen(false);
  }, [dispatch, currentStep, setCartModalIsOpen]);

  const closeCartAndGoTo = useCallback(
    path => {
      closedModalRef.current = true;
      setCartModalIsOpen(false);
      history.push(path);
      dispatch(cartActions.resetCart());
      removeState('cart');
      setCurrentStep(0);
    },
    [history, dispatch, setCartModalIsOpen]
  );

  const visual = {
    hideFormTitle: true,
    style: {
      customVariables: {
        baseColor: '#fc4303',
        baseColorFirstVariant: '#222222',
        baseColorSecondVariant: '#222222',
        borderRadiusSmall: '0px',
        borderRadiusMedium: '0px',
        borderRadiusLarge: '0px'
      }
    }
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <CartSummary
            cart={cart}
            currencyMoney={currencyMoney}
            setCurrencyMoney={setCurrencyMoney}
            onHandleFinishPurchase={onHandleFinishPurchase}
            notEnoughCredits={notEnoughCredits}
            disabledPurchaseCredits={disabledPurchaseCredits}
            handleChooseMoreCourses={handleChooseMoreCourses}
            onCloseModalCart={onCloseModalCart}
            emptyCart={emptyCart}
            currentUser={currentUser}
            setAuthModalIsOpen={setAuthModalIsOpen}
            setAuthType={setAuthType}
            AUTH_LOGIN={AUTH_LOGIN}
          />
        );
      case 1:
        return (
          <>
            <div className="title-bar">
              <div className='title-container'>
                <h3>Informações adicionais</h3>
                <p>Complete as informações abaixo para finalizar a compra:</p>
              </div>
            </div>
            <FormPersonalInfo userData={currentUser} nextStepAfterForm={nextStepAfterForm} />
          </>
        );
      case 2:
        return (
          <PaymentForm
            cart={cart}
            currentUser={currentUser}
            visual={visual}
            nextStep={nextStep}
            currentStep={currentStep}
            setPayment={setPayment}
          />
        );
      case 3:
        return (
          <PaymentStatus
            visual={visual}
            payment={payment}
            currentStep={currentStep}
            closeCartAndGoTo={closeCartAndGoTo}
            previousStep={previousStep}
            setPayment={setPayment}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ScrollLock isActive={(authModalIsOpen && !currentUser?.email) || cartModalIsOpen} />
      <div
        className={classNames('popup__overlay', {
          'popup__overlay--hidden': !cartModalIsOpen || (authModalIsOpen && !currentUser?.email)
        })}
      >
        <div className="popup__background" onClick={onCloseModalCart} />
        <TouchScrollable>
          <div className="popup__screen popup__screen--cart">
            <div className="arrow__right" onClick={onCloseModalCart} />
            <span className="logo">
              <img src={logo} alt="Descola" width={80} height={55} />
            </span>
            <div className={classNames('cart', { cartBlur: cartIsLoading })}>
              {renderStepContent()}
            </div>
            {cartIsLoading && <div className="cart-spinner"></div>}
          </div>
        </TouchScrollable>
      </div>
    </>
  );
};

ModalCart.propTypes = {
  setAuthType: PropTypes.func
};

ModalCart.defaultProps = {
  setAuthType: () => { }
};

export default ModalCart;
