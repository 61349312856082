import axios from 'axios';
import { BASE_URL_API } from '../constants';

// Mock localStorage when it is not allowed
let localStorage;
try {
  localStorage = window.localStorage;
} catch (error) {
  localStorage = {
    getItem: () => undefined,
    setItem: () => { }
  };
}

export const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state, key) => {
  try {
    const serializedState = state ? JSON.stringify(state) : '';
    localStorage.setItem(key, serializedState);
  } catch (error) {
    console.warn(`Error saving state: ${error}`);
  }
};

export const removeState = key => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.warn(`Error removing state: ${error}`);
  }
};

export const getToken = () => loadState('accessToken');
export const setToken = token => saveState(token, 'accessToken');

export const refreshToken = async () => {
  const refreshToken = loadState('refreshToken');
  if (!refreshToken) return null;

  try {
    const response = await axios.post(`${BASE_URL_API}oauth/token`, {
      client_id: 1,
      client_secret: process.env.REACT_APP_SECRET,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });

    const { access_token, refresh_token: newRefreshToken, expires_in } = response.data;

    // Store the new tokens and expiration time
    saveState(access_token, 'accessToken');
    saveState(newRefreshToken, 'refreshToken');
    saveState(Date.now() + expires_in * 1000, 'token_expiry');

    return access_token;
  } catch (error) {
    console.error("Failed to refresh token", error);
    return null;
  }
};
