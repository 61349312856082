import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PAGE_HOME } from '../../constants';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const currentUser = useSelector(state => state.user);

  return (
    <Route
      {...rest}
      render={props =>
        currentUser?.email ? (
          <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
          </Suspense>
        ) : (
          <Redirect to={PAGE_HOME} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};

export default ProtectedRoute;
