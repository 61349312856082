import isEmail from 'validator/lib/isEmail';

export const validateForm = (formRegister, isSocial = false) => {
  const newErrors = {};
  let hasErrors = false;
  const { firstName, lastName, email, password, passwordConfirmation, agreedTerms } = formRegister;

  if (isSocial) {
    if (!agreedTerms) {
      newErrors.agreedTerms = 'Aceite os termos para continuar o cadastro';
    }
    if (Object.keys(newErrors).length) {
      hasErrors = true;
    }
    return { hasErrors, errors: newErrors };
  }

  if (!firstName || !lastName || !email || !password || !passwordConfirmation) {
    newErrors.formError = 'Favor preencher todos os campos';
  }
  if (!agreedTerms) {
    newErrors.agreedTerms = 'Aceite os termos para continuar o cadastro';
  }
  if (password !== passwordConfirmation) {
    newErrors.password = 'Senhas estão diferentes';
  }
  if (email && !isEmail(email)) {
    newErrors.email = 'E-mail inválido';
  }
  if (Object.keys(newErrors).length) {
    hasErrors = true;
  }

  return { hasErrors, errors: newErrors };
};
