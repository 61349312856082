import React, { Suspense, useEffect } from 'react';
import { loadProgressBar } from 'axios-progress-bar';
import { Switch, Redirect } from 'react-router-dom';
import urljoin from 'url-join';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import LoaderRoute from './components/LoaderRoute';
import Header from './components/Header';
import CookieBar from './components/CookieBar';
import api from './api/api';
import {
  PAGE_HOME,
  PAGE_ERROR,
  PAGE_COURSES,
  PAGE_ABOUT,
  PAGE_TERMS_AND_CONDITIONS,
  PAGE_COOKIES_POLICY,
  PAGE_PRIVACY_POLICY,
  PAGE_MY_COURSES,
  PAGE_HELP,
  PAGE_FOR_COMPANIES,
  PAGE_MY_FAVORITES,
  PAGE_CONTACT,
  PAGE_PRESS_OFFICE,
  PAGE_WATCH,
  PAGE_SOCIAL_RETURN,
  PAGE_MY_CERTIFICATES,
  PAGE_MY_PURCHASES,
  PAGE_MY_PROFILE,
  PAGE_ORDER,
  PAGE_SEARCH_RESULTS,
  PAGE_CONTENT_SLUG,
  PAGE_LOGIN,
  PAGE_ATTACHMENTS,
  IS_POWER_FRIDAY_ACTIVE
} from './constants';

import 'react-toastify/dist/ReactToastify.css';

const ErrorPage = React.lazy(() => import('./scenes/Error'));
const SocialReturn = React.lazy(() => import('./scenes/SocialReturn'));
const Courses = React.lazy(() => import('./scenes/Courses'));
const SearchResults = React.lazy(() => import('./scenes/SearchResults'));
const MyProfile = React.lazy(() => import('./scenes/MyProfile'));
const MyCourses = React.lazy(() => import('./scenes/MyCourses'));
const MyFavorites = React.lazy(() => import('./scenes/MyFavorites'));
const MyCertificates = React.lazy(() => import('./scenes/MyCertificates'));
const Order = React.lazy(() => import('./scenes/Order'));
const MyPurchases = React.lazy(() => import('./scenes/MyPurchases'));
const Attachments = React.lazy(() => import('./scenes/Attachments'));
const ForCompanies = React.lazy(() => import('./scenes/ForCompanies'));
const About = React.lazy(() => import('./scenes/About'));
const Help = React.lazy(() => import('./scenes/Help'));
const Contact = React.lazy(() => import('./scenes/Contact'));
const PressOffice = React.lazy(() => import('./scenes/PressOffice'));
const Watch = React.lazy(() => import('./scenes/Watch'));
const TermsAndConditions = React.lazy(() => import('./scenes/TermsAndConditions'));
const CookiesPolicy = React.lazy(() => import('./scenes/CookiesPolicy'));
const PrivacyPolicy = React.lazy(() => import('./scenes/PrivacyPolicy'));
const Slug = React.lazy(() => import('./scenes/Slug'));
const Login = React.lazy(() => import('./scenes/Login'));

loadProgressBar(null, api);

let Home;
let Topbar;

if (IS_POWER_FRIDAY_ACTIVE) {
  Home = React.lazy(() => import('./scenes/Home - PF'));
  Topbar = React.lazy(() => import('./components/PfTopbar'));
} else {
  Home = React.lazy(() => import('./scenes/Home'));
}

const App = () => {
  useEffect(() => {
    // Check if there's a logout message in localStorage
    const logoutMessage = localStorage.getItem('logoutToastMessage');
    if (logoutMessage) {
      toast.error(logoutMessage);
      localStorage.removeItem('logoutToastMessage'); // Clear the message after displaying
    }
  }, []);

  return (
    <>
      <Suspense fallback={<div />}>{IS_POWER_FRIDAY_ACTIVE && <Topbar />}</Suspense>
      <Header />
      <ToastContainer />
      <Suspense fallback={<div />}>
        <Switch>
          <LoaderRoute path={`${PAGE_SOCIAL_RETURN}`} component={SocialReturn} />
          <LoaderRoute path={`${PAGE_LOGIN}`} component={Login} />
          <LoaderRoute path={`${PAGE_COURSES}/categories/:category`} component={Courses} />
          <LoaderRoute path={PAGE_COURSES} component={Courses} />
          <LoaderRoute path={PAGE_SEARCH_RESULTS} component={SearchResults} />
          <LoaderRoute path={PAGE_MY_PROFILE} component={MyProfile} protected />
          <LoaderRoute path={PAGE_MY_COURSES} component={MyCourses} protected />
          <LoaderRoute path={PAGE_MY_FAVORITES} component={MyFavorites} protected />
          <LoaderRoute path={PAGE_MY_CERTIFICATES} component={MyCertificates} protected />
          <LoaderRoute path={PAGE_MY_PURCHASES} component={MyPurchases} protected />
          <LoaderRoute path={`${PAGE_ORDER}/:id`} component={Order} protected />
          <LoaderRoute path={`${PAGE_ATTACHMENTS}/:id`} component={Attachments} />
          <LoaderRoute path={PAGE_ABOUT} component={About} />
          <LoaderRoute path={PAGE_FOR_COMPANIES} component={ForCompanies} />
          <LoaderRoute path={PAGE_HELP} component={Help} />
          <LoaderRoute path={PAGE_CONTACT} component={Contact} />
          <LoaderRoute path={PAGE_PRESS_OFFICE} component={PressOffice} />
          <LoaderRoute path={PAGE_TERMS_AND_CONDITIONS} component={TermsAndConditions} />
          <LoaderRoute path={PAGE_COOKIES_POLICY} component={CookiesPolicy} />
          <LoaderRoute path={PAGE_PRIVACY_POLICY} component={PrivacyPolicy} />
          <LoaderRoute path={`${PAGE_WATCH}/:id`} component={Watch} protected />
          <LoaderRoute path={PAGE_ERROR} component={ErrorPage} />
          <LoaderRoute path={PAGE_HOME} exact component={Home} />
          <LoaderRoute path={urljoin(PAGE_CONTENT_SLUG, ':slug')} component={Slug} />
          <Redirect to={PAGE_ERROR} />
        </Switch>
      </Suspense>
      <CookieBar />
    </>
  )};

export default App;
