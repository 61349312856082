import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { StatusScreen } from '@mercadopago/sdk-react';
import { getPayment } from '../../api/payments';
import { STATUS_PAID, STATUS_WAITING_PAYMENT, STATUS_REFUSED, PAGE_MY_COURSES, PAGE_COURSES, STATUS_PROCESSING } from '../../constants';

const PaymentStatus = ({
  visual,
  payment,
  currentStep,
  closeCartAndGoTo,
  previousStep,
  setPayment
}) => {
  const statusScreenRef = useRef(null);
  const visualRef = useRef(visual);

  useEffect(() => {
    let pollingInterval;

    const pollPaymentStatus = async () => {
      try {
        if (currentStep === 3 && payment) {
          const response = await getPayment(payment.id);
          if (response.data && response.data.data) {
            const newPayment = response.data.data;
            if (newPayment.status !== STATUS_WAITING_PAYMENT && newPayment.status !== STATUS_PROCESSING) {
              statusScreenRef.current = null;
              setPayment(newPayment);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching payment status:', error);
      }
    };

    if (currentStep === 3) {
      pollingInterval = setInterval(pollPaymentStatus, 5000);
    }

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [currentStep, payment, setPayment]);

  const renderStatusScreen = useMemo(() => {
    if (statusScreenRef.current) {
      return null;
    }

    if (!payment) {
      return null;
    }

    return (
      <div>
        <div>
          <StatusScreen
            customization={{ visual: visualRef.current }}
            initialization={{ paymentId: payment.transaction_id }}
          />
        </div>

        {payment.status === STATUS_PAID && (
          <div className="cart__buttons">
            <button
              className="btn btn-primary"
              onClick={() => {
                closeCartAndGoTo(PAGE_MY_COURSES);
              }}
              aria-label="Ir para meus cursos"
            >
              Ir para meus cursos
            </button>
          </div>
        )}

        {payment.status === STATUS_WAITING_PAYMENT && (
          <div className="cart__buttons">
            <button
              className="btn btn-primary"
              onClick={() => closeCartAndGoTo(PAGE_COURSES)}
              aria-label="Continuar vendo cursos"
            >
              Continuar vendo cursos
            </button>
          </div>
        )}

        {payment.status === STATUS_REFUSED && (
          <div className="cart__buttons">
            <button className="btn btn-primary" onClick={previousStep} aria-label="Tentar novamente">
              Tentar novamente
            </button>
          </div>
        )}
      </div>
    );
  }, [payment, closeCartAndGoTo, previousStep]);

  return (
    <>
      <div className="title-bar mb-5">
        <div className='title-container'>
          <h3>Status do pagamento</h3>
        </div>
      </div>
      <div className="mb-5 pb-5" ref={statusScreenRef}>
        {renderStatusScreen}
      </div>
    </>
  );
};

PaymentStatus.propTypes = {
  visual: PropTypes.object.isRequired,
  payment: PropTypes.object,
  currentStep: PropTypes.number.isRequired,
  closeCartAndGoTo: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  setPayment: PropTypes.func.isRequired
};

export default PaymentStatus;
