import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';
import { actions } from '../../reducers/cart';
import formatToCurrency from '../../utils/formatToCurrency';
import { PAGE_HOME } from '../../constants';

const CartSummary = ({
  cart,
  currencyMoney,
  setCurrencyMoney,
  onHandleFinishPurchase,
  notEnoughCredits,
  disabledPurchaseCredits,
  handleChooseMoreCourses,
  onCloseModalCart,
  emptyCart
}) => {
  const dispatch = useDispatch();
  const [coupon, setCoupon] = React.useState('');

  const handleKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        dispatch(actions.validateCouponRequest(coupon));
      }
    },
    [dispatch, coupon]
  );

  const onHandleRemoveProduct = useCallback(
    product => {
      if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
          event: 'removeFromCart',
          ecommerce: {
            remove: {
              products: [
                {
                  name: product.title,
                  id: product.productId,
                  price: product.prices?.salePrice || product.prices?.price,
                  brand: 'Descola',
                  quantity: 1
                }
              ]
            }
          }
        });
      }
      dispatch(actions.removeRequest(product.id));
    },
    [dispatch]
  );

  const totalCart = useMemo(() => {
    const total = cart?.total || 0;
    const discount = cart?.discount || 0;
    const grandTotal = cart?.grandTotal || 0;
    const totalCredits = cart?.totalCredits || 0;

    if (currencyMoney) {
      if (cart.coupon?.id) {
        return (
          <>
            <div className="cart__total__reais--sub">{formatToCurrency(total)}</div>
            <div className="cart__total__reais--off"> - {formatToCurrency(discount)}</div>
            {formatToCurrency(grandTotal)}{' '}
          </>
        );
      }
      return formatToCurrency(total);
    }
    return totalCredits;
  }, [currencyMoney, cart]);

  const totalProduct = useCallback(
    product => {
      if (currencyMoney) {
        const total = product?.prices?.salePrice || product?.prices?.price;
        const discount = product?.prices?.discount || 0;
        const grandTotal = total - discount;

        if (discount) {
          return (
            <>
              <span className="antigo">{formatToCurrency(total)}</span>{' '}
              <span className="novo">{formatToCurrency(grandTotal)}</span>{' '}
            </>
          );
        }
        return formatToCurrency(total);
      }
      const credits = product?.prices?.creditsPrice || 0;

      return credits;
    },
    [currencyMoney]
  );

  const cartItems = useMemo(() => {
    if (!cart) return null;

    return cart.products.map(product => (
      <div key={product.id}>
        <div
          className={classNames('cart__item', {
            'cart__item--secondary': product.type === 3,
            'cart__item--tertiary': product.type === 2
          })}
        >
          <div className="cart__item__img">
            <picture>
              <img
                srcSet={`${product.secondaryImage}?w=72&h=72, ${product.secondaryImage}?w=144&h=144 2x`}
                alt={product.title}
                width={72}
                height={72}
              />
            </picture>
          </div>
          <div className="cart__item__content">
            <h3>{product.title}</h3>
            {product?.preOrder && (
              <p className='pre-order'>
                Pré-venda: Disponível a partir de{' '}
                {isValid(product?.launchDate * 1000) &&
                  format(product?.launchDate * 1000, 'dd/MM/yyyy')}
              </p>
            )}
          </div>
          <div className="cart__item__price">
            valor
            <div
              className={classNames({
                'cart__item__price__reais': currencyMoney,
                'cart__item__price__credits': !currencyMoney
              })}
            >
              {totalProduct(product)}
            </div>
          </div>
          <button
            className="btn btn-remove"
            onClick={() => onHandleRemoveProduct(product)}
            aria-label={`Remover ${product.title} do carrinho`}
          >
            remover
          </button>
        </div>
        <div className="feedback-invalid">
          {!currencyMoney && !product.prices?.creditsPrice && (
            <>Este produto não pode ser comprado com créditos</>
          )}
        </div>
      </div>
    ));
  }, [cart, currencyMoney, totalProduct, onHandleRemoveProduct]);

  return (
    <>
      <div className="title-bar">
        <div className='title-container mb-3'>
          <h3>Resumo do pedido</h3>
        </div>
        {!emptyCart && (
          <div className="btn-group btn-group-toggle mb-3" data-toggle="buttons">
            <button
              className={classNames('btn btn-outline-primary', { active: currencyMoney })}
              onClick={() => setCurrencyMoney(true)}
              aria-label="Pagar com dinheiro"
            >
              Dinheiro
            </button>
            <button
              className={classNames('btn btn-outline-primary', { active: !currencyMoney })}
              onClick={() => setCurrencyMoney(false)}
              aria-label="Pagar com créditos"
            >
              Créditos
            </button>
          </div>
        )}
      </div>
      {!currencyMoney && (
        <div className="cart__credits">
          Seus Créditos
          <div className="cart__credits__value">{cart.availableCredits || 0}</div>
        </div>
      )}
      <div className="cart__itens">
        {emptyCart && (
          <div className="cart__itens__msg">Você ainda não tem nenhum curso no carrinho.</div>
        )}
        {cartItems}
      </div>
      {currencyMoney && !emptyCart && (
        <div className="cart__coupon">
          {cart?.coupon?.id ? (
            <>
              <strong>Cupom adicionado!</strong>
              <div className="card-coupon">
                <h3>{cart.coupon.title}</h3>
                <button
                  className="btn btn-remove"
                  onClick={() => dispatch(actions.removeCouponRequest(coupon))}
                  aria-label={`Remover cupom ${cart.coupon.title}`}
                >
                  remover
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="form-group">
                <label>Possui algum cupom?</label>
                <input
                  className="input-box"
                  type="text"
                  value={coupon}
                  onChange={e => setCoupon(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <button
                  className="btn btn-primary"
                  onClick={() => dispatch(actions.validateCouponRequest(coupon))}
                  aria-label="Adicionar cupom"
                >
                  Adicionar
                </button>
              </div>
              <span className="feedback-invalid">{cart.couponError}</span>
            </>
          )}
        </div>
      )}
      {!emptyCart && (
        <div className="cart__total">
          Total
          <div
            className={classNames({
              'cart__total__reais': currencyMoney,
              'cart__total__credits': !currencyMoney
            })}
          >
            {totalCart}
          </div>
        </div>
      )}

      <div className="cart__buttons">
        {!emptyCart && (
          <>
            <button
              className="btn btn-primary"
              disabled={!cart?.products?.length || disabledPurchaseCredits}
              onClick={onHandleFinishPurchase}
              aria-label="Finalizar compra"
            >
              Finalizar a compra
            </button>
            <div className="feedback-invalid">
              {notEnoughCredits && (
                <>
                  Você não tem créditos suficientes, compre mais{' '}
                  <Link to={`${PAGE_HOME}#creditos`} onClick={onCloseModalCart}>
                    aqui
                  </Link>
                </>
              )}
            </div>
          </>
        )}

        <button
          className="btn btn-outline-primary"
          onClick={handleChooseMoreCourses}
          aria-label="Escolha mais cursos"
        >
          Escolha mais cursos
        </button>
      </div>
    </>
  );
};

CartSummary.propTypes = {
  cart: PropTypes.object.isRequired,
  currencyMoney: PropTypes.bool.isRequired,
  setCurrencyMoney: PropTypes.func.isRequired,
  onHandleFinishPurchase: PropTypes.func.isRequired,
  notEnoughCredits: PropTypes.bool.isRequired,
  disabledPurchaseCredits: PropTypes.bool.isRequired,
  handleChooseMoreCourses: PropTypes.func.isRequired,
  onCloseModalCart: PropTypes.func.isRequired,
  emptyCart: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  setAuthModalIsOpen: PropTypes.func.isRequired,
  setAuthType: PropTypes.func.isRequired,
  AUTH_LOGIN: PropTypes.string.isRequired
};

export default CartSummary;
